var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "el-form",
        {
          ref: "postObj",
          staticClass: "form-container",
          attrs: { model: _vm.postObj, rules: _vm.rules },
        },
        [
          _c(
            "el-steps",
            {
              staticClass: "steps",
              attrs: { active: _vm.active, "finish-status": "success" },
            },
            [
              _c("el-step", { attrs: { title: "基础信息" } }),
              _c("el-step", { attrs: { title: "选择表单" } }),
              _c("el-step", { attrs: { title: "流程设计" } }),
            ],
            1
          ),
          _c("div", { staticClass: "createdPost-content" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active == 0,
                    expression: "active == 0",
                  },
                ],
                staticClass: "createPost-main-container basic",
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "标题",
                              "label-width": "100px",
                              prop: "schemeNameRule",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "200px" },
                              attrs: { name: "name", required: "" },
                              model: {
                                value: _vm.postObj.schemeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.postObj, "schemeName", $$v)
                                },
                                expression: "postObj.schemeName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "100px",
                              label: "模板编号:",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "200px" },
                              attrs: { type: "text" },
                              model: {
                                value: _vm.postObj.schemeCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.postObj, "schemeCode", $$v)
                                },
                                expression: "postObj.schemeCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "postInfo-container-item",
                            attrs: {
                              "label-width": "100px",
                              label: "发布时间:",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetime",
                                format: "yyyy-MM-dd HH:mm:ss",
                                placeholder: "选择日期时间",
                              },
                              model: {
                                value: _vm.postObj.createDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.postObj, "createDate", $$v)
                                },
                                expression: "postObj.createDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "postInfo-container-item",
                            attrs: {
                              "label-width": "100px",
                              label: "重要性(占位):",
                            },
                          },
                          [
                            _c("el-rate", {
                              staticStyle: { "margin-top": "8px" },
                              attrs: {
                                max: 3,
                                colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
                                "low-threshold": 1,
                                "high-threshold": 3,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "100px", label: "摘要:" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 3,
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.postObj.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.postObj, "description", $$v)
                                },
                                expression: "postObj.description",
                              },
                            }),
                            _vm.contentShortLength
                              ? _c("span", { staticClass: "word-counter" }, [
                                  _vm._v(_vm._s(_vm.contentShortLength) + "字"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active == 1,
                    expression: "active == 1",
                  },
                ],
                staticClass: "createPost-main-container",
              },
              [
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "border-card" },
                    on: { "tab-click": _vm.handleTabClick },
                    model: {
                      value: _vm.postObj.frmId,
                      callback: function ($$v) {
                        _vm.$set(_vm.postObj, "frmId", $$v)
                      },
                      expression: "postObj.frmId",
                    },
                  },
                  [
                    _c("div", { staticClass: "createPost-main-model" }),
                    _vm._l(_vm.forms, function (item) {
                      return [
                        _c(
                          "el-tab-pane",
                          {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              name: item.id,
                              id: item.id,
                            },
                          },
                          [
                            item.frmType == 1
                              ? _c(
                                  "el-card",
                                  { staticClass: "box-card" },
                                  [
                                    _c(item.webId + "Add", {
                                      ref: "frmData",
                                      refInFor: true,
                                      tag: "component",
                                    }),
                                  ],
                                  1
                                )
                              : item.frmType === 2
                              ? _c(
                                  "el-card",
                                  [
                                    _vm.contentData.list &&
                                    _vm.contentData.list.length > 0
                                      ? _c("CreatedForm", {
                                          ref: "createdForm",
                                          refInFor: true,
                                          attrs: {
                                            insite: true,
                                            isDisabled: true,
                                            data: _vm.contentData,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.frmPreview),
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm.active == 2
              ? _c(
                  "div",
                  {
                    staticClass: "editor-container",
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c("CreatedFlow", {
                      ref: "createdFlow",
                      attrs: {
                        "form-template": _vm.currentForm,
                        isEdit: _vm.isEdit,
                        "scheme-content": _vm.postObj.schemeContent,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "edit-btns text-center" },
            [
              _vm.active > 0
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.pre },
                    },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
              _vm.active < 2
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.next },
                    },
                    [_vm._v("下一步")]
                  )
                : _vm._e(),
              _vm.active === 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small", type: "success" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("保存 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }